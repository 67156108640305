import React from 'react'
import '../css/services.css'
import Navbar from './Navbar'
import Smain1 from './Smain1'
import Smain2 from './Smain2'
import Footer from './Footer'
import { useEffect } from 'react'

function Services() {

  useEffect(() => {
    const handleLoad = () =>{
      window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
    } 
  
    window.addEventListener('load', handleLoad)
    return () => window.removeEventListener("load", handleLoad);
  }, [])
  return (
    <div className='services'>
        <Navbar page="serv"/>      
        <Smain1/>
        <Smain2/>
        <Footer/>
    </div>
  )
}

export default Services
import React from 'react'
import Solmain3 from './Solmain3'
import Navbar from './Navbar'
import '../css/solutions.css'
import Solmain1 from './Solmain1'
import Solmain2 from './Solmain2'
import Footer from './Footer'

function Solutions() {
  return (
    <div className='solution wwc'>
        <Navbar page="sol"/>
        <Solmain1/>
        <Solmain2/>
        <Solmain3/>
        <Footer  page={'solutions'}/>
    </div>
  )
}

export default Solutions
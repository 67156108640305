import React from 'react'
import { Outlet } from 'react-router-dom'

function ProfMain() {
  return (
    <div className='profMain'>
        <Outlet/>
    </div>
  )
}

export default ProfMain
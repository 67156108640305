import React, { useEffect, useState } from 'react';
import floor1 from '../Images/Consultation/floor1.png'
import spi from '../Images/Consultation/Group 2275.png'
import consult from '../Images/Consultation/support consultancy_Current View.png'
import { Link } from 'react-router-dom';

function Consultation() {

    const [glitch, setGlitch] = useState(false);

  useEffect(() => {
    const glitchInterval = setInterval(() => {
      setGlitch(true);
      setTimeout(() => {
        setGlitch(false);
      }, 200);
    }, 2500);
    return () => clearInterval(glitchInterval);
  }, []);
  const [textStyle, setTextStyle] = useState({
    position: 'relative',
    left: '0'
  })

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    if (windowWidth <= 1024) {
      setTextStyle({
        position: 'relative',
        left:'70px'
        }
      )
    } 
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  const [imgStyle, setImgStyle] = useState({
    transform: 'scale(1)'
  })



  return (
    <div>
    <div className="sb-icons sp-icons">
    <div className="sbi" id='gi'
    style={{ outline: '2px solid white', background: 'black'}}><img src={spi} alt="greenIcon" /></div>
</div>
 <div className="subDesc spDesc">
 <div className="allGlitches spGlitches" style={imgStyle}>
            <div className={`glitch-container ${glitch ? 'glitch' : ''}`}>
            <img src={consult} alt="Glitch Image" className="glitch-image spBack" />
            </div>
            <img src={consult} alt="Glitch Image" className='gi-back spBack'/>
        </div>
    <div className="starter" style={textStyle}>
        <h1>
            <p style={{color: "white"}}>CONSULTATION</p>
        </h1>
        <p className='month-sb'>FAST PACED SOLUTION</p>
        <div className="starter-desc">
            <p>- EXPERIENCED TEAM TO HELP</p>
            <p>- ONLINE MEET</p>
            <p>- SOLUTIONS DOCUMENTED</p>
            <p>- CURATED QUOTATION WITH OUR SERVICES</p>
        </div>

        <Link to={'/contact'}>
        <button className='ac-now' style={{ backgroundColor: '#B4875B', boxShadow: '0px 20px 20px #402E1C' }}>CONTACT</button>
        
        </Link>
    </div>
    <img src={floor1} alt="floor1" className='floor'/>
 </div>
</div>
  )
}

export default Consultation
import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo_black from '../Images/Home/logo_black.png';

function ProfSidebar() {
  const location = useLocation();
  const [path, setPath] = useState(location.pathname);

  useEffect(() => {
    setPath(location.pathname);
  }, [location]);

  return (
    <div className='profSidebar'>
      <Link to={'/'} className="psTop">
        <img src={logo_black} alt="red fibre" />
        <p>RED FIBRE</p>
      </Link>
      <div className="psComps">
        <Link to='/profile' className={path === '/profile' ? 'psc pscSelect' : 'psc'}>
          <div className='img'></div>
          <p>DASHBOARD</p>
        </Link>
        <Link to='/profile/projects' className={path === '/profile/projects' ? 'psc pscSelect' : 'psc'}>
          <div className='img'></div>
          <p>PROJECTS</p>
        </Link>
      </div>
      <div className="pfBottom">
        <div className="pfb"></div>
        <p>Random Name</p>
      </div>
    </div>
  );
}

export default ProfSidebar;

import React from 'react'
import '../css/footer.css'
import rfLogo from '../Images/redfibre logo.png'

function Footer({page}) {
  return (
    <div className='footer' style={{marginTop: page === 'solutions' ? '100px' : ''}}>
        <hr className='ftlin' style={{display: page === 'home' || 'solutions' ? 'none' : 'block'}}/>
        <div className="footerMain">
            <hr />
            <div className="ftCont">
                <div className="rfc">
                    <img src={rfLogo} alt="" />
                    <p>RED FIBRE COMPANY</p>
                </div>
                <div className="allfs">
                    <div className="fs">
                        <div className="f">
                            <h4>DIGITAL BRANDING</h4>
                            <p>Strategy</p>
                            <p>Graphic Design</p>
                            <p>Website Creation</p>
                            <p>Motion & Video</p>
                            <p>Social Media</p>
                            <p>App Creation</p>
                            <p>Sound Design</p>
                            <p>AR & VR</p>
                        </div>
                        <div className="f">
                            <h4>DIGITAL BRANDING</h4>
                            <p>Blogs</p>
                            <p>Brochure</p>
                            <p>Company Profile</p>
                        </div>
                    </div>
                    <div className="fs">
                        <div className="f">
                            <h4>ABOUT RED FIBRE</h4>
                            <p>About Team</p>
                            <p>Newsroom</p>
                            <p>Careers</p>
                            <p>Collaborations</p>
                            <p>For Investors</p>
                            <p>Events</p>
                            <p>Contact</p>
                        </div>
                        <div className="f">
                            <h4>RED FIBRE VALUES</h4>
                            <p>Environment</p>
                            <p>Privacy</p>
                        </div>
                    </div>
                    <div className="fs">
                        <div className="f">
                            <h4>SOLUTIONS</h4>
                            <p>Create Plans</p>
                            <p>Help & Support</p>
                        </div>
                        <div className="f">
                            <h4>ACCOUNT</h4>
                            <p>Manage Account</p>
                            <p>View Cart</p>
                            <p>Order Status</p>
                            <p>Create A Ticket</p>
                            <p>Refferals</p>
                            <p>Gift Card</p>
                        </div>
                    </div>
                    <div className="fs">
                        <div className="f">
                            <h4>SOCIAL MEDIA</h4>
                            <p>Instagram</p>
                            <p>Linkedin</p>
                            <p>Facebook</p>
                            <p>Snapchat</p>
                            <p>X (Twitter)</p>
                        </div>
                    </div>
                </div>
                <hr />
                <div className="ff">
                <p className='cc'>Copyright © 2024 Red Fibre. All Rights Reserved.</p>
                <p className='India'>India</p>
                </div>
                
            </div>
        </div>
    </div>
  )
}

export default Footer
import React, { useState, useEffect } from 'react';
import strategywebsiterf from '../Images/Strategy/strategywebsiterf.png';
import arrow1 from "../Images/Group 2069/Group 2069.png";
import strategywebsiterf_1 from '../Images/Strategy/strategywebsiterf1.png';

function StrtMain2() {
  const gif1 = require("../Images/gif/gif1.mp4");
  const gif2 = require("../Images/gif/gif2.mp4");
  const gif3 = require("../Images/gif/gif3.mp4");

  const gifList = [gif1, gif2, gif3];

  const [randomGif, setRandomGif] = useState(getRandomGif());

  function getRandomGif() {
    const randomIndex = Math.floor(Math.random() * gifList.length);
    return gifList[randomIndex];
  }

  useEffect(() => {
    setRandomGif(getRandomGif());
  }, []);

  return (
    <div className='strtMain2'>
      <div className="wfy">
        <img src={strategywebsiterf} alt="" />
        <div className="wfyt">
          <p>LET THIS STRATEGY SOLUTION <br /> WORK FOR YOU, EVERY WEEK.</p>
          <button className="wfy-btn">Learn more</button>
        </div>
      </div>
      <div className="wwc">
        <h1 className='dsd'>LET’S CONNECT TO GET YOUR DESIRED SERVICE DONE</h1>
        <div className="cu">
          <video width="640" height="360" className='cuImg' autoPlay loop muted>
              <source src={randomGif} type="video/mp4"/>
              Your browser does not support the video tag.
          </video>
          <div className="lm-btn em-btn strt-btn">
            <button className="lm em">
              Contact Us
              <div className="arrows em-arr">
                <img src={arrow1} alt="arrow1" className="arrow1" />
                <img src={arrow1} alt="arrow1" className="arrow2" />
              </div>
            </button>
            <div className="btn-r"></div>
            <div className="btn-w"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StrtMain2;

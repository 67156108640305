import React, { useState } from 'react';
import '../css/loginSignup.css';
import logoBlack from '../Images/Home/logo_black.png';
import { Link } from 'react-router-dom';

function Signup() {

    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [focusedField, setFocusedField] = useState(null);

    const handleFocus = (field) => {
        console.log(`Focused: ${field}`); // Debugging log
        setFocusedField(field);
    };

    const handleBlur = () => {
        console.log(`Blurred: ${focusedField}`); // Debugging log
        setFocusedField(null);
    };

    const handleSubmit = async(e) => {
        e.preventDefault();

        try {
            const formdata = {
                username, 
                email,
                password
            };
            console.log(formdata);

            const response = await fetch('http://localhost:5000/users/signup', {
                method: "POST",
                body: JSON.stringify(formdata),
                headers: {
                    'Content-Type': 'application/json'
                },
            });

            const json = await response.json();
            if (response.ok) {
                console.log('Signed up successfully');
            } else {
                console.log('Signup failed:', json);
            }

        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div className='lsMain'>
            <form className="loginSignup" onSubmit={handleSubmit}>
                <Link to={'/'}>
                    <img src={logoBlack} alt="rf logo"/>
                </Link>
                <h1>SIGNUP</h1>
                <div className="lp">
                    <div className={`lsep ${focusedField === 'username' ? 'focused' : ''}`}>
                        <input 
                            type="text"  
                            placeholder='Username' 
                            onChange={(e) => setUsername(e.target.value)}
                            onFocus={() => handleFocus('username')}
                            onBlur={handleBlur}
                        />
                    </div>
                    <div className={`lsep ${focusedField === 'email' ? 'focused' : ''}`}>
                        <input 
                            type="email"  
                            placeholder='Email' 
                            onChange={(e) => setEmail(e.target.value)}
                            onFocus={() => handleFocus('email')}
                            onBlur={handleBlur}
                        />
                    </div>
                    <div className={`lsep ${focusedField === 'password' ? 'focused' : ''}`}>
                        <input 
                            type="password"  
                            placeholder='Password' 
                            onChange={(e) => setPassword(e.target.value)}
                            onFocus={() => handleFocus('password')}
                            onBlur={handleBlur}
                        />
                    </div>
                    <button>Signup</button>
                </div>
                
                <p>New to RedFibre? <Link to={'/login'}>login</Link></p>
            </form>
        </div>
    )
}

export default Signup;

import React from 'react'
import { useState, useEffect } from 'react';
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css"; 
import '../css/contact.css'
import meet from '../Images/meet.png'
import check from '../Images/check.png'
import Navbar from './Navbar'
import cntImg from "../Images/Group 2215.png"
import wtspImg from '../Images/wtspImg.png'
import { Link } from 'react-router-dom';
import Footer from './Footer';

function Contact() {

  const [selectedOption, setSelectedOption] = useState("");
  // const [phoneNumber, setPhoneNumber] = useState();

  
  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };


  const [topDBI, setTopDBI] = useState();
    const [topTAA, setTopTAA] = useState();
    const [topDBP, setTopDBP] = useState();
    const [topMS, setTopMS] = useState();
    const [topDA, setTopDA] = useState();

    useEffect(() => {
        const handleLoad = () => {
            const targetDiv = document.getElementById("DBI");
            const targetDiv2 = document.getElementById("TAA");
            const targetDiv3 = document.getElementById("DBP");
            const targetDiv4 = document.getElementById("MS");
            const targetDiv5 = document.getElementById("DA");

            if (targetDiv) {
                const rect = targetDiv.getBoundingClientRect();
                setTopDBI(rect.top);
                console.log("Top position of the div1:", rect.top);
            }

            if (targetDiv2) {
                const rect2 = targetDiv2.getBoundingClientRect();
                setTopTAA(rect2.top);
                console.log("Top position of the div2:", rect2.top);
            }

            if (targetDiv3) {
                const rect3 = targetDiv3.getBoundingClientRect();
                setTopDBP(rect3.top);
                console.log("Top position of the div3:", rect3.top);
            }

            if (targetDiv4) {
                const rect4 = targetDiv4.getBoundingClientRect();
                setTopMS(rect4.top);
                console.log("Top position of the div4:", rect4.top);
            }

            if (targetDiv5) {
                const rect5 = targetDiv5.getBoundingClientRect();
                setTopDA(rect5.top);
                console.log("Top position of the div5:", rect5.top);
            }

            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        };

        window.addEventListener("load", handleLoad);
        return () => window.removeEventListener("load", handleLoad);
    }, []);


    const scrollToDBI = () => {
        window.scrollTo({
            top: topDBI - 100,
            behavior: "smooth",
        });
    };

    const scrollToTAA = () => {
        window.scrollTo({
            top: topTAA - 100,
            behavior: "smooth",
        });
    };

    const scrollToDBP = () => {
        window.scrollTo({
            top: topDBP - 100,
            behavior: "smooth",
        });
    };

    const scrollToMS = () => {
        window.scrollTo({
            top: topMS - 100,
            behavior: "smooth",
        });
    };

    const scrollToDA = () => {
        window.scrollTo({
            top: topDA - 100,
            behavior: "smooth",
        });
    };


    const [location, setLocation] = useState('')
    const [country, setCountry]  = useState('')
    const [dateTime, setDateTime] = useState('')
    const [companyName, setCompanyName] = useState('')
    const [companyEmail, setCompanyEmail] = useState('')
    const [yourName, setYourName] = useState('')
    const [companyDes, setCompanyDes] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [companyAddress, setCompanyAddress] = useState('')

    const handleLocationChange = (event) => {
      setLocation(event.target.value);
    };

    const handleCountryChange = (event) => {
      setCountry(event.target.value);
    };



    const handleVisits = async(e) => {
        e.preventDefault();
        
        try{
          const formData = {
            location: location,
            country: country,
            dateTime: dateTime,
            companyName: companyName,
            companyEmail: companyEmail,
            yourName: yourName,
            companyDes: companyDes,
            phoneNumber: phoneNumber,
            companyAddress: companyAddress

          }

          console.log(formData);  
          
          const response = await fetch('http://localhost:5000/visits/createBooking', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData)
          });

          if(!response.ok)
          {
            throw new Error('Failed to send data');
          }

          const data = await response.json();
          console.log('Success:', data);
          
        }catch(error)
        {
          console.log(error)
        }
    };


    const [mCompanyName, setMCompanyName] = useState('')
    const [mYourName, setMYourName] = useState('')
    const [mCompanyDes, setMCompanyDes] = useState('')
    const [mCompanyEmail, setMCompanyEmail] = useState('')
    const [mPhoneNumber, setMPhoneNumber] = useState('')
    const [mGoogleMeet, setMGoogleMeet] = useState(false)

    const [isChecked, setChecked] = useState(false);
    const [cbStyle, setCbStyle] = useState({
      display: "none"
    });

  const handleClick = () => {
    const cb = document.getElementById('checkbox')
    const cb2 = document.getElementById('checkbox2')
      
      if(!isChecked)
      {
        setCbStyle({
          display: "inline-block",
          position: 'absolute',
          zIndex: '-1'
        })
        cb.classList.add('checkTsp')
        cb2.classList.add('checkTsp')
        setChecked(true)
        setMGoogleMeet(false)
      }else{
        setCbStyle({
          display: "none"
        })
        setChecked(false)
        cb.classList.remove('checkTsp')
        cb2.classList.remove('checkTsp')
        setMGoogleMeet(true)
      }
      
      
  };

    const handleMeet = async(e) => {
        e.preventDefault();
        
        try{
          const formData = {
            companyName: mCompanyName,
            yourName: mYourName,
            companyDes: mCompanyDes,
            companyEmail: mCompanyEmail,
            phoneNumber: mPhoneNumber,
            googleMeet: mGoogleMeet
          }

          console.log(formData);  
          
          const response = await fetch('http://localhost:5000/meets/createMeet', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData)
          });

          if(!response.ok)
          {
            throw new Error('Failed to send data');
          }

          const data = await response.json();
          console.log('Success:', data);
          
        }catch(error)
        {
          console.log(error)
        }
    };

    const [service, setService] = useState('')
    const [feature, setFeature] = useState('')
    const [solution, setSolution] = useState('')
    const [qCompanyName, setQCompanyName] = useState('')
    const [qYourName, setQYourName] = useState('')
    const [qCompanyDes, setQCompanyDes] = useState('')
    const [qCompanyEmail, setQCompanyEmail] = useState('')
    const [qPhoneNumber, setQPhoneNumber] = useState('')
    const [qAdditionalBrief, setQAdditionalBrief] = useState('')
    const [qGoogleMeet, setQGoogleMeet] = useState('')

    const [isChecked2, setChecked2] = useState(false);
    const [cbStyle2, setCbStyle2] = useState({
      display: "none"
    });

  const handleClick2 = () => {
    const cb = document.getElementById('checkbox')
    const cb2 = document.getElementById('checkbox2')
      
      if(!isChecked2)
      {
        setCbStyle2({
          display: "inline-block",
          position: 'absolute',
          zIndex: '-1'
        })
        cb.classList.add('checkTsp')
        cb2.classList.add('checkTsp')
        setChecked(true)
        setQGoogleMeet(false)
      }else{
        setCbStyle2({
          display: "none"
        })
        setChecked2(false)
        cb.classList.remove('checkTsp')
        cb2.classList.remove('checkTsp')
        setQGoogleMeet(true)
      }
      
      
  };

    const handleQuote = async(e) => {
      e.preventDefault();
      
      try{  
        const formData = {
          service: service,
          feature: feature,
          solution: solution,
          companyName: qCompanyName,
          yourName: qYourName,
          companyDes: qCompanyDes,
          companyEmail: qCompanyEmail,
          phoneNumber: qPhoneNumber,
          additionalBrief: qAdditionalBrief,
          googleMeet: qGoogleMeet
        }

        console.log(formData);  
        
        const response = await fetch('http://localhost:5000/quotes/createQuote', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData)
        });

        if(!response.ok)
        {
          throw new Error('Failed to send data');
        }

        const data = await response.json();
        console.log('Success:', data);
        
      }catch(error)
      {
        console.log(error)
      }
  };

  const openWhatsApp = () => {
    const phoneNumber = '7038887201'; // Replace with the phone number you want to message
    const message = 'Hello, I would like to contact you'; // Optional: Replace with your custom message
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

    window.open(whatsappUrl, '_blank');
  };

  return (
    <div className="wwc">
      <Navbar page="cont"/>
      <div className='contact'>
          <img src={cntImg} alt="cntImg" className='cntImg' />
          <div className="contHead">
            <div className="sc">
              <h1>STAY CONNECTED</h1>
              <span className='scWhite'></span><div className="scun"></div>
            </div>
            <div className="wtsp">
              <h1>WE ARE AVAILABLE FOR CHAT, VIRTUALLY 24*7</h1>
              <button onClick={openWhatsApp} >CHAT WITH US <img src={wtspImg} alt="wtspImg" className='wtspImg'/></button>
            </div>
            <h1>MORE WAYS TO CONNECT</h1>
            <div className="all-feats">
            <div className="af" onClick={scrollToDBI}>
                <div className="whiteGlow"></div>
                <p className='afp'>OFFLINE MEET</p>
            </div>
            <div className="af" onClick={scrollToTAA} >
                <div className="whiteGlow"></div>
                <p className='afp'>GET A QUOTE</p>
            </div>
            <div className="af" onClick={scrollToDBP}>
                <div className="whiteGlow"></div>
                <p className='afp'>INVESTORS / PARTNERS</p>
            </div>
            <div className="af" onClick={scrollToMS}>
                <div className="whiteGlow"></div>
                <p className='afp'>CAREERS</p>
            </div>
            <div className="af" onClick={scrollToDA}>
                <div className="whiteGlow"></div>
                <p className='afp'>HELP & SUPPORT</p>
            </div>
            
        </div>

          </div>

        <div className="allWays">
          <div className="aw" id='DBI'>
            <div className="oro">
              <h1>OUR REGISTERED OFFICE</h1>
              <p>108, 1st Floor, B-Wing, Udyog Bhawan-1, Ambernath Industrial Park-MIDC, Ambernath, Mumbai, India, 421506</p>
              <p className='otv'>OUR TEAM IS VIRTUAL BASED</p>
              <p>Book our visit :</p>
            </div>
            <form  className='contact-form oro-form' onSubmit={handleVisits}>
            <div className="c1">
              <select value={location} onChange={handleLocationChange}>
                <option value="Mumbai">Mumbai</option>
                <option option value="Navi Mumbai">Navi Mumbai</option>
                <option value="Thane">Thane</option>
                <option value="Pune">Pune</option>
              </select>
              <select value={country} onChange={handleCountryChange}>
                <option value="India">India</option>
              </select>
              {/* <select value={selectedOption} onChange={handleSelectChange}>
                <option value="">Date & Time</option>
                <option option value="Option 1">Option 1</option>
                <option value="Option 2">Option 2</option>
              </select>  */}
              <input type="date" className='dateTime' onChange={(e)=>{setDateTime(e.target.value)}}/>
            </div>
            <div className="c2">
              <input type="text" className='cn' placeholder='Company Name' onChange={(e)=>{setCompanyName(e.target.value)}}/>
              <input type="text" className='yn' placeholder='Your Name' onChange={(e)=>{setYourName(e.target.value)}}/>
            </div>
            <div className="c3">
              <input type="text" className='cd' placeholder='Company Designation' onChange={(e)=>{setCompanyDes(e.target.value)}}/>
              <input type="email" className='cd' placeholder='Company Email' onChange={(e)=>{setCompanyEmail(e.target.value)}}/>
              <div className="phn">
              <PhoneInput
                placeholder="Phone Number"
                value={phoneNumber}
                onChange={setPhoneNumber}
              />
              </div>
            </div>
            <textarea name="additional_brief" id="" cols="30" rows="10" placeholder='Company Address' onChange={(e)=>{setCompanyAddress(e.target.value)}}></textarea>
            <div className="oro-yellow">**We will confirm our availability to you through Email and WhatsApp</div>
            
          <div className="gaq-btn">
            <button className='gaq'>CONFIRM BOOKING</button>
          </div>
          </form>
          </div>

          <div className="aw" id='TAA'>
            <h1>FOR INVESTORS AND PARTNERS</h1>
            <form action="" className='contact-form' onSubmit={handleMeet}>
            <div className="c2">
              <input type="text" className='cn' placeholder='Company Name' onChange={(e)=>setMCompanyName(e.target.value)}/>
              <input type="text" className='yn' placeholder='Your Name' onChange={(e)=>setMYourName(e.target.value)}/>
            </div>
            <div className="c3">
              <input type="text" className='cd' placeholder='Company Designation' onChange={(e)=>setMCompanyDes(e.target.value)}/>
              <input type="email" className='cd' placeholder='Company Email' onChange={(e)=>setMCompanyEmail(e.target.value)}/>
              <div className="phn">
              <PhoneInput
                placeholder="Phone Number"
                value={mPhoneNumber}
                onChange={setMPhoneNumber}
              />
              </div>
            </div>
            <div className="meetCheck">
              <input
                type="checkbox"
                checked={isChecked}
              />
              <span 
                className='checkbox'
                onClick={handleClick}
                id='checkbox'
              ></span>
              <img src={check} alt="check" className='checkbox' style={cbStyle}/>
              <label htmlFor="nothing">CONNECT USING GOOGLE MEET <img src={meet} alt="meet" /></label>
            </div>
          <div className="gaq-btn">
          <button className='gaq'>CONFIRM MEETING</button>
          </div>
          </form>
          </div>

          <div className="aw" id='DBP'>
          <h1>GET A QUOTE</h1>
          <form action="" className='contact-form' onSubmit={handleQuote}>
            <div className="c1">
              <select value={service} onChange={(e)=>setService(e.target.value)}>
                <option value="">SELECT SERVICE</option>
                <option option value="Strategy">Strategy</option>
                <option value="Graphic Design">Graphic Design</option>
                <option value="Website Creation">Website Creation</option>
                <option value="Motion & Video">Motion & Video</option>
                <option value="Social Media">Social Media</option>
                <option value="App Creation">App Creation</option>
                <option value="Sound Design">Sound Design</option>
                <option value="AR & VR">AR & VR</option>
              </select>
              {/* <select value={feature} onChange={(e)=>setFeature(e.target.value)}>
                <option value="">SELECT FEATURE</option>
                <option option value="Option 1">Option 1</option>
                <option value="Option 2">Option 2</option>
              </select>
              <p>--OR--</p> */}
              <select value={solution} onChange={(e)=>setSolution(e.target.value)}>
                <option value="">SELECT SOLUTION</option>
                <option option value="Subscriptions - Starter">Subscriptions - Starter</option>
                <option value="Subscriptions - Social">Subscriptions - Social</option>
                <option value="Subscriptions - Motion">Subscriptions - Motion</option>
                <option value="Subscriptions - Trend+">Subscriptions - Trend+</option>
                <option value="Subscriptions - Pro">Subscriptions - Pro</option>
                <option value="Support-Pack - Graphic Design">Support-Pack - Graphic Design</option>
                <option value="Support-Pack - Develop/Deploy">Support-Pack - Develop/Deploy</option>
                <option value="Consultation">Consultation</option>
              </select> 
            </div>
            <div className="c2">
              <input type="text" className='cn' placeholder='Company Name' onChange={(e)=>setQCompanyName(e.target.value)}/>
              <input type="text" className='yn' placeholder='Your Name' onChange={(e)=>setQYourName(e.target.value)}/>
            </div>
            <div className="c3">
              <input type="text" className='cd' placeholder='Company Designation' onChange={(e)=>setQCompanyDes(e.target.value)}/>
              <input type="email" className='cd' placeholder='Company Email' onChange={(e)=>setQCompanyEmail(e.target.value)}/>
              <div className="phn">
              <PhoneInput
                placeholder="Phone Number"
                value={qPhoneNumber}
                onChange={setQPhoneNumber}
              />
              </div>
            </div>
            <textarea name="additional_brief" id="" cols="30" rows="10" placeholder='Additional Brief' onChange={(e)=>setQAdditionalBrief(e.target.value)}></textarea>
            <div className="meetCheck">
              <input
                type="checkbox"
                checked={isChecked2}
              />
              <span 
                className='checkbox'
                onClick={handleClick2}
                id='checkbox2'
              ></span>
              <img src={check} alt="check" className='checkbox' style={cbStyle2}/>
              <label htmlFor="nothing">CONNECT USING GOOGLE MEET <img src={meet} alt="meet" /></label>
            </div>
          <div className="gaq-btn">
          <button className='gaq'>GET A QUOTE</button>
          </div>
          </form>
          </div>

          <div className="aw" id='MS'>
            <h1 className='aw-careers'>CAREERS</h1>
            <p className='caw'>Send your resume at <a href='mailto:hr@redfibre.co'>hr@redfibre.co</a></p>
          </div>

          <div className="aw" id='MS'>
            <h1 className='aw-careers'>PROJECTS</h1>
            <p className='caw'>Send your projects at <a href='mailto:project@redfibre.co'>project@redfibre.co</a></p>
          </div>

          <div className="aw" id='DA'>
            <h1 className='aw-careers'>HELP & SUPPORT</h1>
            <div className="wtsp">
              <button onClick={openWhatsApp}>CHAT WITH US <img src={wtspImg} alt="wtspImg" className='wtspImg'/></button>
            </div>
            <p className='caw'>Or send your query at <a href='mailto:redfibre7@gmail.com'>redfibre7@gmail.com</a></p>
          </div>
        </div>

        
    </div>
    <Footer/>
    </div>
  )
}

export default Contact
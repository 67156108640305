import React, { useState } from 'react'
import '../css/loginSignup.css'
import logoBlack from '../Images/Home/logo_black.png'
import { Link } from 'react-router-dom'

function Login() {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [focusedField, setFocusedField] = useState(null); // Track the currently focused field

    const handleFocus = (field) => {
        setFocusedField(field);
    };

    const handleBlur = () => {
        setFocusedField(null);
    };

    const handleSubmit = async(e)=>{
        e.preventDefault();

        try {
            const formdata = {
                email,
                password
            };
            console.log(formdata);
            const response = await fetch('http://localhost:5000/users/login',{
                method: "POST",
                body: JSON.stringify(formdata),
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            const json = await response.json();
            if (response.ok) {
                console.log('Logged in successfully');
            } else {
                console.log('Login failed:', json);
            }

        } catch(error) {
            console.log(error);
        }
    };

    return (
        <div className='lsMain'>
            <form className="loginSignup" onSubmit={handleSubmit}>
                <Link to={'/'}>
                <img src={logoBlack} alt="rf logo"/>
                </Link>
                <h1>LOGIN</h1>
                <div className="lp">
                    <div className={`lsep ${focusedField === 'email' ? 'focused' : ''}`}>
                        <input 
                            type="email" 
                            id='lsEmail' 
                            placeholder='Email' 
                            onChange={(e) => setEmail(e.target.value)}
                            onFocus={() => handleFocus('email')}
                            onBlur={handleBlur}
                        />
                    </div>
                    <div className={`lsep ${focusedField === 'password' ? 'focused' : ''}`}>
                        <input 
                            type="password" 
                            id='lsPassword' 
                            placeholder='Password' 
                            onChange={(e) => setPassword(e.target.value)}
                            onFocus={() => handleFocus('password')}
                            onBlur={handleBlur}
                        />
                    </div>
                    <button>Login</button>
                </div>
                <p>New to RedFibre? <Link to={'/signup'}>signup</Link></p>
            </form>
        </div>
    )
}

export default Login;

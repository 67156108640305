import React, { useState } from 'react'
import strategywebsiterf from '../Images/Strategy/strategywebsiterf.png'
import strategywebsiterf_1 from '../Images/Strategy/strategywebsiterf1.png'
import strategywebsiterf_2 from '../Images/Strategy/strategywebsiterf2.png'
import { useEffect } from 'react'

function KeyFeat() {
    const [scrollPosition, setScrollPosition] = useState(0);
    const [tp, setTp] = useState({});
    const [tp2, setTp2] = useState();
    const [tp3, setTp3] = useState();
    const [tp4, setTp4] = useState();
    const [tp5, setTp5] = useState();

    useEffect(() => {
        window.addEventListener('scroll', ()=>{
            if(window.scrollY===220)
            {
                setTp({
                    top: "220px",
                    position: "fixed"
                });
            }
        } )
      const handleScroll = () => {
        if(window.scrollY===220)
        {
            setScrollPosition(220);
        }
        
      };
  
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }, []);
    const [topDBI, setTopDBI] = useState();
    const [topTAA, setTopTAA] = useState();
    const [topDBP, setTopDBP] = useState();
    const [topMS, setTopMS] = useState();
    const [topDA, setTopDA] = useState();

   

    useEffect(() => {
        const handleLoad = () => {
            const targetDiv = document.getElementById("DBI");
            const targetDiv2 = document.getElementById("TAA");
            const targetDiv3 = document.getElementById("DBP");
            const targetDiv4 = document.getElementById("MS");
            const targetDiv5 = document.getElementById("DA");

            if (targetDiv) {
                const rect = targetDiv.getBoundingClientRect();
                setTopDBI(rect.top);
                console.log("Top position of the div1:", rect.top);
            }

            if (targetDiv2) {
                const rect2 = targetDiv2.getBoundingClientRect();
                setTopTAA(rect2.top);
                console.log("Top position of the div2:", rect2.top);
            }

            if (targetDiv3) {
                const rect3 = targetDiv3.getBoundingClientRect();
                setTopDBP(rect3.top);
                console.log("Top position of the div3:", rect3.top);
            }

            if (targetDiv4) {
                const rect4 = targetDiv4.getBoundingClientRect();
                setTopMS(rect4.top);
                console.log("Top position of the div4:", rect4.top);
            }

            if (targetDiv5) {
                const rect5 = targetDiv5.getBoundingClientRect();
                setTopDA(rect5.top);
                console.log("Top position of the div5:", rect5.top);
            }

            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        };

        window.addEventListener("load", handleLoad);
        return () => window.removeEventListener("load", handleLoad);
    }, []);


    const scrollToDBI = () => {
        setActiveCardIndex(0);
        window.scrollTo({
            top: topDBI - 100,
            behavior: "smooth",
        });
    };

    const scrollToTAA = () => {
        setActiveCardIndex(0);
        window.scrollTo({
            top: topTAA - 100,
            behavior: "smooth",
        });
    };

    const scrollToDBP = () => {
        setActiveCardIndex(0);
        window.scrollTo({
            top: topDBP - 100,
            behavior: "smooth",
        });
    };

    const scrollToMS = () => {
        setActiveCardIndex(0);
        window.scrollTo({
            top: topMS - 100,
            behavior: "smooth",
        });
    };

    const scrollToDA = () => {
        setActiveCardIndex(0);
        window.scrollTo({
            top: topDA - 100,
            behavior: "smooth",
        });
    };

    const [activeCardIndex, setActiveCardIndex] = useState(0);

    useEffect(() => {
        const link = document.createElement('link');
        link.href = 'https://scroll-driven-animations.style/demos/stacking-cards/css/';
        link.rel = 'stylesheet';
        document.head.appendChild(link);
    
        return () => {
          document.head.removeChild(link);
        };
      }, []);

  return (
    <div className="wwc">
        <h1>KEY FEATURES</h1>
        {/* <div className="all-feats">
            <div className="af" onClick={scrollToDBI}>
                <div className="whiteGlow"></div>
                <p className='afp'>DEFINING BRAND IDENTITY</p>
            </div>
            <div className="af" onClick={scrollToTAA} >
                <div className="whiteGlow"></div>
                <p className='afp'>TARGET AUDIENCE ANALYSIS</p>
            </div>
            <div className="af" onClick={scrollToDBP}>
                <div className="whiteGlow"></div>
                <p className='afp'>DIGITAL BRANDING PLANNING</p>
            </div>
            <div className="af" onClick={scrollToMS}>
                <div className="whiteGlow"></div>
                <p className='afp'>MARKETING STRATEGIES</p>
            </div>
            <div className="af" onClick={scrollToDA}>
                <div className="whiteGlow"></div>
                <p className='afp'>DATA ANALYTICS</p>
            </div>
            
        </div> */}
        <div className="allFtImgs" id="cards">
            <div className="afi card"  id='DBI card_1'>
                <div className="afiImg"><img src={strategywebsiterf} alt="strategywebsiterf" style={{width: '100%'}}/></div>
                <div className='afidesc'>
                    <h2>DEFINING BRAND IDENTITY</h2>
                    <p>Crafting a brand identity is like creating a captivating character for a story. Your brand is the hero, with unique values and a distinct personality. The logo, colors, and design are the hero’s attire, making them instantly recognizable. As your brand embarks on its narrative, consistency becomes the golden thread stitching each chapter together. It’s about creating a story that resonates with your audience, forming connections that turn consumers into loyal companions on your brand’s adventure.</p>
                </div>
            </div>
            <div className="afi card" id='TAA card_2'>
                <div className="afiImg"><img src={strategywebsiterf_1} alt="strategywebsiterf_1" /></div>
                <div className="afidesc">
                <h2>TARGET AUDIENCE ANALYSIS</h2>
                <p>In digital branding, understanding the target audience is a bit like being a friendly detective who wants to know what makes people happy and interested. Just like how each person has different hobbies, favorite colors, and things they enjoy, the target audience is a group of people who share similar interests. By learning about what they like and how they behave online, brands can create messages and designs that really connect with them. It’s like knowing the secrets to make each person feel special and understood, so they become friends with the brand!</p>
                </div>
            </div>
            <div className="afi card"  id='DBP card_3' >
                <div className="afiImg"><img src={strategywebsiterf_2} alt="strategywebsiterf_2"    /></div>
                <div className="afidesc">
                <h2>DIGITAL BRANDING PLANNING</h2>
                <p>In digital branding, understanding the target audience is a bit like being a friendly detective who wants to know what makes people happy and interested. Just like how each person has different hobbies, favorite colors, and things they enjoy, the target audience is a group of people who share similar interests. By learning about what they like and how they behave online, brands can create messages and designs that really connect with them. It’s like knowing the secrets to make each person feel special and understood, so they become friends with the brand!</p>
                </div>
            </div>
            <div className="afi card"  id='MS card_4'>
            <div className="afiImg"><img src={strategywebsiterf} alt="strategywebsiterf" /></div>
                <div className="afidesc">
                <h2>MARKETING STRATEGIES</h2>
                <p>In digital branding, understanding the target audience is a bit like being a friendly detective who wants to know what makes people happy and interested. Just like how each person has different hobbies, favorite colors, and things they enjoy, the target audience is a group of people who share similar interests. By learning about what they like and how they behave online, brands can create messages and designs that really connect with them. It’s like knowing the secrets to make each person feel special and understood, so they become friends with the brand!</p>
                </div>
            </div>
            <div className="afi card" id='DA card_5'>
            <div className="afiImg"><img src={strategywebsiterf} alt="strategywebsiterf" /></div>
                <div className="afidesc">
                <h2>DATA ANALYTICS</h2>
                <p>In digital branding, understanding the target audience is a bit like being a friendly detective who wants to know what makes people happy and interested. Just like how each person has different hobbies, favorite colors, and things they enjoy, the target audience is a group of people who share similar interests. By learning about what they like and how they behave online, brands can create messages and designs that really connect with them. It’s like knowing the secrets to make each person feel special and understood, so they become friends with the brand!</p>
                </div>
            </div>
            <div>
      
    </div>
        </div>
    </div>
  )
}

export default KeyFeat
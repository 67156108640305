import React from 'react'
import Navbar from './Navbar'
import '../css/strategy.css'
import StrtMain1 from './StrtMain1'
import KeyFeat from './KeyFeat'
import StrtMain2 from './StrtMain2'
import Footer from './Footer'

function Strategy() {
  return (
    <div className='StrategyMain'>
        <Navbar page="serv"/>
        <StrtMain1/>
        <KeyFeat/>
        <StrtMain2/>
        <Footer/>
    </div>
  )
}

export default Strategy
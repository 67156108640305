import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import AHCbanner from '../Images/Services/AHCbanner.jpeg';
import AHCposter from '../Images/Services/AHCposter.jpeg';
import AHdigital from '../Images/Services/AHdigital.jpeg';
import smpd from '../Images/Services/smpd.jpeg';
import TIwebsite from '../Images/Services/TIwebsite.jpeg';
import TIfablab from '../Images/Services/TIfablab.jpeg';
import TIiconography from '../Images/Services/TIiconography.jpeg';
import BhawarsWeb from '../Images/Services/BhawarsWeb.jpeg';
import mushroom from '../Images/Services/mushroom.jpeg';
import pipetech from '../Images/Services/pipetech.png';
import sky from '../Images/Services/rfpost3d-2-Current View.png';
import spotlightvideo from '../Images/Services/spotlightvideo.mp4';
import loadMore from '../Images/loadMore.png';

function Smain2() {
  const allProjects = [
    { id: 1, media: AHCbanner, name: 'CAMPAIGN BANNER FOR ARTISTIC HUMANS', type: 'image' },
    { id: 2, media: BhawarsWeb, name: 'BHAWARS ENGINEERING WEB ICONOGRAPHY', type: 'image' },
    { id: 3, media: TIwebsite, name: 'TI LOGO AND WEBSITE BRANDING', type: 'image' },
    { id: 4, media: TIfablab, name: 'ISOMETRIC ILLUSTRATION FOR Fablab & TI', type: 'image' },
    { id: 5, media: AHCposter, name: 'CREATIVE SHOW POSTER FOR ARTISTIC HUMANS', type: 'image' },
    { id: 6, media: smpd, name: 'ILLUSTRATION FOR SOCIAL MEDIA- PERSONAL BRANDING', type: 'image' },
    { id: 7, media: TIiconography, name: 'USER MANUAL DESIGN, ART & ICONOGRAPHY FOR TI', type: 'image' },
    { id: 8, media: AHdigital, name: 'DIGITAL ART BACKGROUND (print) FOR ARTISTIC HUMANS', type: 'image' },
    { id: 9, media: pipetech, name: 'PIPETECH 10TH YEAR BROCHURE COVER', type: 'image' },
    { id: 10, media: mushroom, name: 'MUSHROOM TALES PACKAGING', type: 'image' },
  ];

  const videoProject = {
    id: 11, media: spotlightvideo, name: 'VIDEO ANIMATION FOR SOCIAL PLATFORMS -PIPETECH', type: 'video'
  };

  const [projects, setProjects] = useState(getRandomProjects(allProjects));
  const [click, setClick] = useState(1);

  function getRandomProjects(projects) {
    let shuffled = projects.sort(() => 0.5 - Math.random());
    return shuffled.slice(0, 2);
  }

  const handleLoadMoreClick = () => {
    setClick((prevClick) => {
      const newClick = prevClick + 1;

      if (newClick % 4 === 0) {
        setProjects([videoProject]);
      } else {
        setProjects(getRandomProjects(allProjects));
      }

      return newClick;
    });
  };

  return (
    <div className="smain2">
      <div className="wwc">
        <h1>PROJECTS IN SPOTLIGHT</h1>
        <div className="prjts">
          <div className="prjt1"></div>
          <div className="prjt2"></div>
          <div className="prjt3"></div>
          <div className="prjtLinks">
            {projects.map((project) => (
              <Link key={project.id} className="prjt4" style={{ width: project.type === 'image' ? '49.8%' : '100%' }}>
                {project.type === 'image' ? (
                  <img src={project.media} alt={project.name} />
                ) : (
                  <video object-fit='cover' autoPlay>
                    <source src={project.media} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                )}
                <div className="prjt-hide">{project.name}</div>
              </Link>
            ))}
          </div>
          <div className="loadMore" onClick={handleLoadMoreClick}>
            <img src={loadMore} alt="loadMore" />
            <p>Load More</p>
          </div>
        </div>
      </div>
      <div className="wwc">
        <h1>QUALITY OF OUR SERVICES</h1>
        <div className="quality">
          <img src={sky} alt="sky" />
          <p>Our services will play a pivotal role in shaping the online identity and success of businesses. Our approach is defined by a commitment to excellence and a deep understanding of the intricacies of digital branding. <br /> <br /> We collaborate closely with our clients to grasp their vision and goals. Our team of seasoned designers, developers, and digital strategists then crafts a tailor-made plan to ensure your every product not only looks exceptional but also functions seamlessly. Our professionals work diligently, employing their expertise to meet project milestones efficiently. This dedication to meeting deadlines is part of our unwavering commitment to client satisfaction. <br /><br /> At RED FIBRE, we take pride in our ability to transform ideas into compelling digital experiences that leave a lasting impression. Call us your trusted partners in the digital realm, dedicated to helping your business thrive.</p>
        </div>
      </div>
    </div>
  );
}

export default Smain2;

import React from 'react'
import { Link } from 'react-router-dom'

function Blur() {
  return (
    // blur code 
    <div className='blur'>
        Coming Soon!!
        <Link to={'/'}>
        <button>
        Back to home    
        </button>
        </Link>
    </div>
  )
}

export default Blur
import React from 'react'
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'

function Smain1() {
    const [ra, setRa] = useState('0')
    const [ra2, setRa2] = useState('0')
    const [ra3, setRa3] = useState('0')

        // setTimeout(() => {
        //     setRa('480px');
        // }, 400);
    
        // setTimeout(() => {
        //     setRa2('350px');
        // }, 300);
    
        // setTimeout(() => { 
        //     setRa3('200px'); 
        // }, 100);


        useEffect(() => {
            const screenWidth = window.innerWidth;
            
            if (screenWidth <= 1024) {
                setTimeout(() => {
                    setRa('250px');
                }, 400);
        
                setTimeout(() => {
                    setRa2('170px');
                }, 300);
        
                setTimeout(() => { 
                    setRa3('90px'); 
                }, 100);
            } else {
                setTimeout(() => {
                    setRa('480px');
                }, 400);
        
                setTimeout(() => {
                    setRa2('350px');
                }, 300);
        
                setTimeout(() => { 
                    setRa3('200px'); 
                }, 100);
            }
        }, []);
  
  return (
    <>
        <div className='smain1'>
        <div className="allrdm">
            <div className='rdm'>
                <div className="red-ani" style={{width: ra}}>
                    <div className="whiteGlow"></div>
                    <div className="rdlin"></div>
                </div>
                <p>Red Fibre</p>
            </div>
            <div className="rdm rdm2" >  
                <div className="red-ani2" style={{width: ra2}}>
                    <div className="whiteGlow"></div>
                    <div className="rdlin"></div>
                </div>
                <p>Design Agency</p>
            </div>
            <div className="rdm rdm3">
                <div className="red-ani3" style={{width: ra3}}>
                    <div className="whiteGlow"></div>
                    <div className="rdlin"></div>
                </div>
                <p>Marketing Agency</p>
            </div>
        </div>

        <div className="servHead">
            <h1>
                OUR SERVICES ARE <br /> 20 TIMES MORE <br /> PROEFFICIENT <br /> IN MARKET
            </h1>
            <h2>BECAUSE OF OUR WORK MODELS</h2>
        </div>
        </div>
        <div className="wwc">
            <h1>WHAT WE CREATE</h1>
            <div className="crtns">
                <Link >STRATEGY</Link>
                <Link>GRAPHIC DESIGN</Link>
                <Link>WEBSITE CREATION</Link>
                <Link>MOTION & VIDEO</Link>
                <Link>SOCIAL MEDIA</Link>
                <Link>APP CREATION</Link>
                <Link>SOUND DESIGN <div className='new'>NEW</div></Link>
                <Link>AR & VR <div className='new'>NEW</div></Link>
            </div>
        </div>
    </>
  )
}

export default Smain1
import React from 'react'
import ProfSidebar from './ProfSidebar'
import ProfMain from './ProfMain'
import '../css/profile.css'
import Blur from './Blur'

function Profile() {
  return (
    <div className='profileComp'>
      <Blur/>
      <ProfSidebar/>
      <ProfMain/>
    </div>
  )
}

export default Profile
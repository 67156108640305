import React from 'react'
import rfMainLogo from '../Images/rwdfibre logo big.png'
import arrow1 from '../Images/Group 2069/Group 2069.png'
import homeHero from '../Images/Home/Home_hero.png'
import rightag from '../Images/rightag.png';

function Hmain1() {

    const handleSlide = () => {
        window.scrollTo({
          top: window.innerHeight - 80,
          behavior: 'smooth'
        });
      };
  return (
    <div className="hmm">
        <div className='hmain1'>
            <div className="hmLeft">
            <h1 className='hmTitle'>
                <span className='rfRed'>LETS <span>ASCEND</span></span>
                <span><span className='rfBr'>BRAND</span> EXPERIENCES</span>
                <span>LIKE NOWHERE</span>
            </h1>
            <p className='hmDesc'>
            Welcome to Red Fibre— crafting digital brands that captivate, grow, and endure. With creativity, strategy, and tech, we elevate your brand in today’s digital world.
            </p>
            <div className="lm-btn">
                <button onClick={handleSlide} className='lm'>
                    Learn More 
                    <div className="arrows">
                        <img src={rightag} alt="arrow1" className='arrow1'/>
                        <img src={rightag} alt="arrow1" className='arrow2'/>
                    </div>
                </button>
                <div className="btn-r"></div>
                <div className="btn-w"></div>
            </div>
            </div>
        {/* <div className="hmRight"> */}
        <img src={homeHero} alt="homeHero" className='homeHero'/>
        {/* </div> */}
    </div>
    </div>
  )
}

export default Hmain1
import React, { useEffect, useState } from 'react';

function Solmain1() {
    const [ebt, setEbt] = useState({
        bottom: "-50px"
    })
    const [solHead, setSolHead] = useState({
        position: 'relative'
    })

    const handleScroll = () => {
        const scrollPosition = window.scrollY;

      console.log('Scroll position:', scrollPosition);
        setEbt({
            bottom: window.scrollY > 1  ? "130px" : "-50px"
            }); 
              

        if(solHead.position === 'relative'){
            setEbt({
                bottom: window.scrollY > 1 ? "130px" : "-50px"
              });
        }

        
        
          setSolHead(prevState => ({
            position: window.scrollY > 1 ? 'relative' : 'fixed'
          }));
        
      };
    
      useEffect(() => {
        // Attach the scroll event listener when the component mounts
        window.addEventListener('scroll', handleScroll);
    
        // Detach the scroll event listener when the component unmounts
        return () => {
          window.removeEventListener('scroll', handleScroll);
          
        };
      }, []);

  return (
    <div className='wwc'>
        <div className="solmain">
            <h1 style={solHead}>
                ASCENDING WITH LATEST TECHNOLOGY
            </h1>
           
          
        </div>
        <h2 style={ebt} className='sol-h2'>EVERY-BRANDING-TIME</h2>
    </div>
  )
}

export default Solmain1
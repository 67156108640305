import React, { useState } from 'react'
import logo_black from '../Images/Home/logo_black.png';

function Dashboard() {
    const [status, setStatus] = useState(false)
    const [pfTime, setPfTime] = useState('')
    const latestUpdate = {
        date: "20 JULY 2024",
        desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. At impedit voluptate vel cumque neque nisi exercitationem, porro pariatur culpa natus veniam minus dolor minima necessitatibus in quidem eos omnis, aspernatur adipisci facilis quas assumenda dolorem! Obcaecati culpa aut error odio nihil animi, necessitatibus quaerat hic cupiditate id sint inventore! Facilis et quisquam distinctio nihil odio eos numquam reiciendis optio similique? Aliquid aspernatur obcaecati cum a minus eligendi quia ullam ut."
    }
    
    const handleStatus = (sts) =>{
        setStatus(!sts)
        setStatus2(false)
        setStatus3(false)
    }
    const [status2, setStatus2] = useState(false)
    
    const handleStatus2 = (sts) =>{
        setStatus(false)
        setStatus3(false)
        setStatus2(!sts)
    }
    const [status3, setStatus3] = useState(false)
    
    const handleStatus3 = (sts) =>{
        setStatus(false)
        setStatus2(false)
        setStatus3(!sts)
    }

    const [clicked, setClicked] = useState({}); // State to track clicked paragraphs

  const handleClick = (index) => {
    setClicked((prev) => ({ ...prev, [index]: !prev[index] })); // Toggle the clicked state
  };    
  const [rrbStyle, setRrbStyle] = useState(false)
  const handleRrb = ()=>{
    setRrbStyle(!rrbStyle)
  }
  return (
    <div className='dashboardMain'>
        <div className="dsLeft">
            <h1 className='dsDate'>28 JULY</h1>
            <h2 className='dsDay'>SUNDAY</h2>
            <div className="allStatus">
                <div className="ptp">
                    <label className="ptpCheck" htmlFor='ck1'  onClick={()=>handleStatus(status)}></label>
                    <input type="checkbox" id='ck1'/>
                    <p>Project status: on time</p>
                    <div className="ipBox" style={{display: status  ? 'flex' : 'none'}}>
                        <div className="ipbColors">
                            <div className="ipbg"></div>
                            <div className="ipbr"></div>
                            <div className="ipby"></div>
                            <div className="ipbc" onClick={()=>handleStatus(status)}>X</div>
                        </div>
                        <textarea type="text" placeholder='Add status' className='ipi'/>
                        <button>Add</button>
                    </div>
                </div>
                <div className="ptp" >
                <label className="ptpCheck" htmlFor='ck2'  onClick={()=>handleStatus2(status2)}></label>
                <input type="checkbox" id='ck2'/>
                    <p>Team Working</p>
                    <div className="ipBox" style={{display: status2  ? 'flex' : 'none'}}>
                        <div className="ipbColors">
                            <div className="ipbg"></div>
                            <div className="ipbr"></div>
                            <div className="ipby"></div>
                            <div className="ipbc" onClick={()=>handleStatus2(status2)}>X</div>
                        </div>
                        <textarea type="text" placeholder='Add status' className='ipi'/>
                        <button>Add</button>
                    </div>
                </div>
                <div className="ptp" >
                <label className="ptpCheck" htmlFor='ck3'   onClick={()=>handleStatus3(status3)}></label>
                <input type="checkbox" id='ck3'/>
                    <p>Pending Required Resources</p>
                    <div className="ipBox" style={{display: status3  ? 'flex' : 'none'}} >

                        <div className="ipbColors">
                            <div className="ipbg"></div>
                            <div className="ipbr"></div>
                            <div className="ipby"></div>
                            <div className="ipbc" onClick={()=>handleStatus3(status3)}>X</div>
                        </div>
                        <textarea type="text" placeholder='Add status' className='ipi'/>
                        <button>Add</button>
                    </div>
                </div>
            </div>
            <div className="som">
                <p>Schedule Online Meet</p>
                <div className="somTime">
                    <label htmlFor='smTime'>Preferred Time</label>
                    <input type="time" id='smTime' onChange={(e)=>setPfTime(e.target.value)}/>
                    {/* <p>{pfTime}</p> */}
                    <button>Request</button>
                </div>
            </div>
            <div className="rdu">
                <p>Request Detailed Update</p>
            </div>
            <input type="text" placeholder='Add Comment for today. Start Typing.' className='addCmt'/>
            <div className="fileInput">
                <img src={logo_black} alt="" />
                <label className="fi" htmlFor='atcFile'>
                    <p>Attach new file, folders or link</p>
                    <p>(Disappears in 42 hours)</p>
                </label>
                <input type="file" id='atcFile'/>
            </div>
        </div>
        <div className="dsRight">
            <div className="reqRes" onClick={()=>handleRrb(rrbStyle)}>
                <p>Required Resources</p>
            </div>
            <div className="rrBox" style={{display: rrbStyle  ? 'flex' : 'none'}}>
                <div className="rrb">
                    <div className="rrbClose" onClick={()=>handleRrb(rrbStyle)}>X</div>
                {["Paragraph 1", "Paragraph 2", "Paragraph 3"].map((text, index) => (
                    <p
                    key={index}
                    className={clicked[index] ? 'line-through' : ''}
                    onClick={() => handleClick(index)}
                    >
                    {text}
                    </p>
                ))}
                </div>  
            </div> 
            {/* <div className="timeline"></div> */}
            <div className="ltsUpdate">
                <p>Latest Update</p>
                <p>{latestUpdate.date}</p>
                <p>{latestUpdate.desc}</p>
            </div>
        </div>
    </div>
  )
}

export default Dashboard
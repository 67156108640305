import React from 'react'
import StrtImg from '../Images/Strategy/Group 2199.png'
import arrow1 from '../Images/Group 2069/Group 2069.png'

function StrtMain1() {
  return (
    <div className="StrtHead">
            <img src={StrtImg} alt="StrtImg" className='StrtImg'/>
            <p>A crucial fact in digital branding is the impact of trust on consumer perceptions. According to Edelman, 81% of consumers believe that trust is vital in a brand. A well-executed digital branding strategy emphasizing authenticity and transparency can build and maintain this trust, fostering lasting connections with the audience.</p>
            <div className="lm-btn">
                <button className='lm'>
                    Service Features
                    <div className="arrows">
                        <img src={arrow1} alt="arrow1" className='arrow1'/>
                        <img src={arrow1} alt="arrow1" className='arrow2'/>
                    </div>
                </button>
                <div className="btn-r"></div>
                <div className="btn-w"></div>
            </div>
    </div>
  )
}

export default StrtMain1
import React, { useEffect, useState } from 'react';
import floor1 from '../Images/SupportPack/floor1.png'
import floor2 from '../Images/SupportPack/floor2.png'
import gd from '../Images/SupportPack/Group 2266.png'
import dd from '../Images/SupportPack/Group 2269.png'
import supportCan from '../Images/SupportPack/support can 1.png'
import supportCan2 from '../Images/SupportPack/support cans 2.png'
import { Link } from 'react-router-dom';

function SupportPack() {

    const [glitch, setGlitch] = useState(false);

  useEffect(() => {
    const glitchInterval = setInterval(() => {
      setGlitch(true);
      setTimeout(() => {
        setGlitch(false);
      }, 200);
    }, 2500);
    return () => clearInterval(glitchInterval);
  }, []);

  const [stHead, setStHead] = useState('GRAPHIC DESIGN')
  const [selectedColor, setSelectedColor] = useState('white');
  const [textColor, setTextColor] = useState('#FFFFFF')
  const [acShadow, setAcShadow] = useState('#FFFFFF3B')
  const [textStyle, setTextStyle] = useState({
    position: 'relative',
    left: '0'
  })

  // suport pack txt adjust

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [lft, setLft] = useState('0px');

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    if (windowWidth <= 1024) {
      setTextStyle({
        position: 'relative',
        left:'70px'
        },
        setLft('70px')
      )
    } 
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // const getContainerClass = () => {
  //   if (windowWidth === 1024) {
  //     setTextStyle({
  //     left:'70px'
  //       }
  //     )
  //   } 
  // };


  const [imgStyle, setImgStyle] = useState({
    transform: 'scale(1)'
  })
  const [content, setContent] = useState([
    "- PROFESSIONAL CREATIVE TEAM",
    "- YOUR DEADLINES",
    "- FASTER WORK",
    "- FASTER DELIVERY"
  ]);
  const [normalImg, setNormalImg] = useState(supportCan)
  const [floor, setFloor] = useState(floor1)

  const handleColorClick = (color) => {
    setTextStyle({
        position: 'relative',
        left: '-400px',
        opacity: '0',
        transform: 'scale(0.7)'
    })
    setImgStyle({
        transform: 'scale(0.7)'
    })
    setTimeout(() => {
        setTextStyle({
            position: 'relative',
            left: lft,
            opacity: '1',
            transform: 'scale(1)'
        })
        setImgStyle({
            transform: 'scale(1)'
        })
    }, 500);
    
    setTimeout(() => {
        setSelectedColor(color);
        if(color === 'white'){
            setTextColor('#FFFFFF')
            setAcShadow('#FFFFFF3B')
            setStHead('GRAPHIC DESIGN')
            setContent([
              "- PROFESSIONAL CREATIVE TEAM",
              "- YOUR DEADLINES",
              "- FASTER WORK",
              "- FASTER DELIVERY"
            ])
            setNormalImg(supportCan)
            setFloor(floor1)
        }else if(color === 'blue'){
          setTextColor('#FFFFFF')
            setAcShadow('#FFFFFF3B')
            setStHead('DEVELOP/DEPLOY')
            setContent([
                "- HIGH END DEVELOPERS",
                "- YOUR DEADLINES",
                "- FASTER WORK",
                "- FASTER DELIVERY"
              ]);
            setNormalImg(supportCan2)
            setFloor(floor2)
        }
    }, 500);
  };

  return (
    <div>
        <div className="sb-icons sp-icons">
        <div className="sbi" id='gi' onClick={() => handleColorClick('white')}
        style={{ outline: selectedColor === 'white' ? '2px solid white' : 'none', background: selectedColor === 'white' ? 'black' : 'transparent' }}><img src={gd} alt="greenIcon" /></div>
        <div className="sbi" id='bi' onClick={() => handleColorClick('blue')}
        style={{ outline: selectedColor === 'blue' ? '2px solid white' : 'none', background: selectedColor === 'blue' ? 'black' : 'transparent' }}><img src={dd} alt="blueIcon" /></div>
        
    </div>
     <div className="subDesc spDesc">
        <div className="allGlitches spGlitches" style={imgStyle}>
            <div className={`glitch-container ${glitch ? 'glitch' : ''}`}>
            <img src={normalImg} alt="Glitch Image" className="glitch-image" />
            </div>
            <img src={normalImg} alt="Glitch Image" className='gi-back spBack'/>
        </div>
        <div className="starter " style={textStyle}>
            <h1>
                {/* <p className='st-db' style={{ color: textColor }}>DIGITAL BRANDING</p> */}
                <p style={{color: "white"}}>{stHead}</p>
            </h1>
            <p className='month-sb'>ON-PROJECT SOLUTION</p>
            <div className="starter-desc">
                {content.map((item, index) => (
                    <p key={index}>{item}</p>
                ))}
            </div>

            <Link to={'/contact'}>
            <button className='ac-now'  style={{ backgroundColor: textColor, boxShadow: `0px 20px 20px ${acShadow}`, color: 'black', fontWeight: 'bold' }}>CONTACT</button>
            
            </Link>
        </div>
        <img src={floor} alt="floor1" className='floor'/>
     </div>
    </div>
  )
}

export default SupportPack
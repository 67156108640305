import React from 'react'
import solbImg from '../Images/Solutions/Image 7.png'

function Solmain2() {
  return (
    <div className='wwc solmain2'>
        <h1>INTRODUCING CUSTOM SOLUTIONS FOR BUSINESSES</h1>
        <div className="solb">
            <img src={solbImg} alt="" />
            <p>In the dynamic landscape of the digital world, our digital branding company takes pride in offering tailored and innovative custom solutions designed specifically for businesses seeking a unique online presence. <br /> <br /> With a keen understanding of the diverse needs of our clients, we specialize in crafting bespoke digital strategies that go beyond the conventional. <br /> <br /> Our team of experts is dedicated to delivering personalized solutions that resonate with your brand identity. Embracing creativity and cutting-edge technologies, our custom solutions are a testament to our commitment to elevating your brand in the digital sphere.</p>
        </div>
    </div>
  )
}

export default Solmain2
import React, { useState, useEffect } from 'react';
import rfLogo from '../Images/redfibre logo.png';
import logoBlack from '../Images/Home/logo_black.png';
import { Link } from 'react-router-dom';

function Navbar({ page }) {
  const [isOpen, setIsOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const descriptions = ["Strategy", "Design", "Development", "Motion", "Social"];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % descriptions.length);
    }, 2000); // Change the name every 2 seconds
    return () => clearInterval(interval);
  }, [descriptions.length]);

  return (
    <nav style={{ backgroundColor: page === 'home' ? 'white' : 'black', position: 'fixed', zIndex: 1001 }}>
     <Link className="rfLogo" to={'/'}>
            <img src={page === 'home' ? logoBlack : rfLogo} alt="" />
            <div className="desBox">
              <div className="des">
                  <div className="de" style={{color : page === 'home' ? '#696969' : 'white'}}>Strategy</div>
                  <div className="de" style={{color : page === 'home' ? '#696969' : 'white'}}>Design</div>
                  <div className="de" style={{color : page === 'home' ? '#696969' : 'white'}}>Development</div>
                  <div className="de" style={{color : page === 'home' ? '#696969' : 'white'}}>Motion</div>
                  <div className="de" style={{color : page === 'home' ? '#696969' : 'white'}}>Social</div>
              </div>
            </div>
        </Link>
      <button className="navbar-toggler" onClick={toggleNavbar}>
        {isOpen ? '✖' : '☰'}
      </button>
      <ul className={`navComp ${isOpen ? 'open' : ''}`}>
        <li><Link to={'/services'} style={page === "serv" ? { color: 'red' } : { color: page === 'home' ? '#696969' : '#696969' }}>SERVICES</Link></li>
        <li><Link to={'/solutions'} style={page === "sol" ? { color: 'red' } : { color: page === 'home' ? '#696969' : '#696969' }}>SOLUTIONS</Link></li>
        <li><Link to={'/contact'} style={page === "cont" ? { color: 'red' } : { color: page === 'home' ? '#696969' : '#696969' }}>CONTACT</Link></li>
        <li><Link to={'/profile'} style={page === "prof" ? { color: 'red' } : { color: page === 'home' ? '#696969' : '#696969' }}>PROFILE</Link></li>
        {/* <li><img src={cart} alt="cart" /></li> */}
      </ul>
    </nav>
  );
}

export default Navbar;
